import {
	PROJECT_ALLOCATIONS,
	PROJECT_TAILORING_HEADER,
	PROCESS_PANEL_HISTORY,
	PROJECT_TAILORING_VERSIONS,
	SAVE_SUBMIT_TAILORING,
	ADD_TAILORING_PROCESS_NOTE,
	GET_TAILORING_PROCESS_NOTES,
	DELETE_TAILORING_PROCESS_NOTES,
} from "../../constants/api-paths";
import axios from "../interceptors";
import envVariables from "../../utils/config.json";
import {
	ISaveSubmitProjectTailorParams,
	ITailoringSaveType,
	TailoringSaveType,
} from "../../utils/@types/ProjectTailor";

const baseUrl = process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL;

interface tailoringHeaderParams {
  accessToken: string;
  query?:string
}

export interface IAllocationService {
  accessToken: string;
  projectId: string;
}

export interface ITailoringHistoryHeaderParams {
  accessToken: string;
  tailoringProcessId: string;
}

export interface ITailoredProcessNoteParams {
  projectId?: string;
  tailoredProcessId: number;
  note: string;
  currentUserProjectRole: string;
  tailoringStatusId: number;
}

export interface IReferenceDeleteHeaderParams {
  accessToken: string;
  tailoredProcessNoteId: string;
  projectId: string
}


const getTailoringHeader = async (params: tailoringHeaderParams) => {
  const bearer = `Bearer ${params.accessToken}`;
  const tailoringRecordId=params.query;
  return axios({
    method: "GET",
    url: `${baseUrl}${PROJECT_TAILORING_HEADER}/${tailoringRecordId}/tailored-header`,
    headers: { authorization: bearer },
  });
};

const getProjectAllocations = async ({
  accessToken,
  projectId,
}: IAllocationService) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${baseUrl}${PROJECT_ALLOCATIONS(projectId)}`,
    headers: { authorization: bearer },
  });
};

const getTailoringHistory = async ({
  accessToken,
  tailoringProcessId,
}: ITailoringHistoryHeaderParams) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${baseUrl}${PROCESS_PANEL_HISTORY(
      tailoringProcessId
    )}`,
    headers: { authorization: bearer },
  });
};

const getTailoringVersions = async ({
  accessToken,
  tailoringProcessId,
}: ITailoringHistoryHeaderParams) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${baseUrl}${PROJECT_TAILORING_VERSIONS(
      tailoringProcessId
    )}`,
    headers: { authorization: bearer },
  });
};

export interface ISaveSubmitProjectTailorService {
  params: ISaveSubmitProjectTailorParams;
	tailoringSaveType: TailoringSaveType;
	accessToken: string;
	tailoringRecordId: number;
}

const saveSubmitProjectTailorService = async ({
	accessToken,
	tailoringRecordId,
	params,
	tailoringSaveType,
}: ISaveSubmitProjectTailorService) => {
	const bearer = `Bearer ${accessToken}`;

	const data: ISaveSubmitProjectTailorParams = {
		...params,
		saveType: ITailoringSaveType[tailoringSaveType],
	};

	return axios.post(baseUrl + SAVE_SUBMIT_TAILORING(tailoringRecordId), {
		...data,
	},{
    headers: {
			Authorization: `Bearer ${accessToken}`,
		},
  });
};

const getTailoredProcessNotesService = async ({
	accessToken,
	tailoredProcessId,
}: {accessToken:string, tailoredProcessId: number} ) => {
	const bearer = `Bearer ${accessToken}`;
	return axios({
		method: "GET",
		url: `${baseUrl}${GET_TAILORING_PROCESS_NOTES( tailoredProcessId )}`,
		headers: { authorization: bearer },
	});
};

const addTailoredProcessNoteService = async ({
	accessToken,
	projectId,
	tailoredProcessId,
	note,
	currentUserProjectRole,
	tailoringStatusId,
}: ITailoredProcessNoteParams & {accessToken:string} ) => {
	const data = {
		projectId,
		tailoredProcessId,
		note,
		currentUserProjectRole,
		tailoringStatusId,
	};
	return axios.post(
		baseUrl + ADD_TAILORING_PROCESS_NOTE,
		{
			...data,
		},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
};

const updateTailoredProcessNoteService = async ({
	accessToken,
	...params
}: ITailoredProcessNoteParams & { accessToken: string; id: string }) => {
	return axios.put(
		baseUrl + ADD_TAILORING_PROCESS_NOTE,
		{
			...params,
		},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
};

const deleteTailoredProcessNoteService = async ({
accessToken,
  tailoredProcessNoteId,
  projectId
}: IReferenceDeleteHeaderParams) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "DELETE",
    url: `${baseUrl}${DELETE_TAILORING_PROCESS_NOTES(tailoredProcessNoteId, projectId)}`,
    headers: { authorization: bearer },
  });
};

export {
	getTailoringHeader,
	getProjectAllocations,
	getTailoringHistory,
	getTailoringVersions,
	saveSubmitProjectTailorService,
	addTailoredProcessNoteService,
	updateTailoredProcessNoteService,
	getTailoredProcessNotesService,
	deleteTailoredProcessNoteService
};
