import { graphConfig } from "../services/authService/authConfig";
import { axiosMockAdapterInstance as mock } from "../services/interceptors";
import { b64toBlob } from "../utils/functions";
import userFeatureMockData from "./data/mock-user-features-api-data.json";

import mockData from "./data/mock-user-api-data.json";
import { USER_FEATURES } from "../constants/api-paths";

import envVariables from "../utils/config.json";

const { userDetailsResponse, userImageResponse } = mockData;
const userFeaturesResponse = userFeatureMockData;

const mockGetUserDetails = () => {
  mock.onGet(graphConfig.graphMeEndpoint).reply(200, userDetailsResponse);
};

const mockGetUserImage = () => {
  const blobResponse = b64toBlob(userImageResponse, "image/png");
  mock.onGet(graphConfig.graphMeImageEndpoint).reply(200, blobResponse);
};

const mockGetUserFeatures = () => {
  mock
    .onGet(`${process.env.REACT_APP_AUTH_BASE_URL || envVariables.REACT_APP_AUTH_BASE_URL}${USER_FEATURES}`)
    .reply(200, userFeaturesResponse);
};

export { mockGetUserDetails, mockGetUserImage, mockGetUserFeatures };
