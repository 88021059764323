import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ProjectListTypes } from "../../features/ProjectLists/type";
import { getAccessToken } from "../../services/authService";
import {
  getAllProjectsList as getAllProjectsListService,
  getMyProjectsList as getMyProjectsListService,
  getPendingApprovalList as getPendingApprovalListService,
} from "../../services/projectsService";
import { RootState } from "../store";

type Status = "idle" | "loading" | "failed";

export interface State {
  projectList: IProjectListProps;
  status: Status;
}

interface IProjectListProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  list: IProjectDetails[];
}

interface IProjectDetails {
  id: number;
  name: string;
  type: string;
  initiator: string;
  initiatedDate: string;
  reviewer: string;
  approvedDate: string;
  aging: number;
  status: string;
  isRetailored: boolean;
}

interface IProjectListParameters {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  query: string;
  listType: ProjectListTypes;
}

const initialState: State = {
  projectList: {
    totalCount: 0,
    pageSize: 1,
    currentPage: 1,
    totalPages: 1,
    list: [],
  },
  status: "loading",
};

export const getProjectsList = createAsyncThunk(
  `allProjects/getProjectsList`,
  async (params: IProjectListParameters) => {
    let response: AxiosResponse<any, any>;
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);

    if (params.listType === "all") {
      response = await getAllProjectsListService({accessToken, query: params.query});
    } else if (params.listType === "my") {
      response = await getMyProjectsListService({accessToken, query: params.query});
    } else {
      response = await getPendingApprovalListService({accessToken, query: params.query});
    }

    const data = response.data;
    return {
      ...data,
    };
  }
);

export const projectListSlice = createSlice({
  name: "projectList",
  initialState,
  reducers: {
    setInitialStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
      state.projectList.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProjectsList.fulfilled, (state, action) => {
        state.projectList.totalCount = action.payload.totalCount
          ? action.payload.totalCount
          : "";
        state.projectList.pageSize = action.payload.pageSize
          ? action.payload.pageSize
          : "";
        state.projectList.currentPage = action.payload.currentPage
          ? action.payload.currentPage
          : "";
        state.projectList.totalPages = action.payload.totalPages
          ? action.payload.totalPages
          : "";
        state.projectList.list = action.payload.items
          ? action.payload.items.length > 0
            ? action.payload.items
            : []
          : [];
        state.status = "idle";
      })
      .addCase(getProjectsList.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setInitialStatus } = projectListSlice.actions;

export const selectProjectList = (state: RootState) =>
  state.projectList.projectList;
export const selectloadingStatus = (state: RootState) =>
  state.projectList.status;

export default projectListSlice.reducer;
