import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getToolsService } from "../../services/processService";

export interface toolState {
	data: { id: number; name: string }[];
	status: "idle" | "loading" | "failed" | "success";
}

const initialState: toolState = {
	data: [],
	status: "idle",
};

interface IToolsParameters {
	instance: IPublicClientApplication;
	accounts: AccountInfo[];
}

export const getTools = createAsyncThunk("tool/getTools", async (params:IToolsParameters) => {
	const accessToken = await getAccessToken(params.instance, params.accounts[0]);
	const response = await getToolsService(accessToken);
	return response.data;
});

export const toolSlice = createSlice({
	name: "tool",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getTools.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getTools.fulfilled, (state, action) => {
				state.status = "success";
				state.data = action.payload;
			})
			.addCase(getTools.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export const selectTool = (state: { tool: toolState }) => state.tool;

export default toolSlice.reducer;
