import {
  ActionIcon,
  Collapse,
  Flex,
  Grid,
  Image,
  Navbar,
  Skeleton,
  Stack,
  Table,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { IconChevronsLeft, IconChevronsRight, IconLogout } from "@tabler/icons";
import { useEffect, useMemo, useState } from "react";
import Logo from "../../assets/images/logo.png";
import envVariables from "../../utils/config.json";
import DividerComponent from "../common/Divider";
import useStyles from "./styles";
import { CollapseIconProps, ISideNavProps, NavbarLinkProps } from "./type";

//Icon SVGs
import { WithFeatureAuthorization } from "../../hocs/withFeatureAuthorization";
import { AvatarTypes, TypographyTypes } from "../../utils/enum";
import Avatar from "../common/Avatar";
import Typography from "../common/Typography";

const CollapseIcon = ({ active, onClick, collapsed }: CollapseIconProps) => {
  const { classes, cx } = useStyles();
  return (
    <UnstyledButton
      onClick={onClick}
      className={cx(classes.link, { [classes.active]: active })}
      data-testid="collapse-icon"
    >
      {collapsed ? (
        <IconChevronsRight stroke={1.5} />
      ) : (
        <IconChevronsLeft stroke={1.5} />
      )}
    </UnstyledButton>
  );
};

const NavbarLink = ({
  icon,
  label,
  active,
  onClick,
  collapsed,
}: NavbarLinkProps & { collapsed: boolean }) => {
  const { classes, cx } = useStyles();
  return (
    <UnstyledButton
      onClick={onClick}
      className={
        collapsed
          ? cx(classes.link, { [classes.collapsedActive]: active })
          : cx(classes.collapseLink, { [classes.active]: active })
      }
    >
      {!collapsed ? (
        <>
          <span className={classes.expandedMenuIcons}>{icon}</span>
          <Collapse in={!collapsed} transitionDuration={200}>
            <span style={{ marginLeft: 10 }} data-testid="navigation-menu-item">
              {label}
            </span>
          </Collapse>
        </>
      ) : (
        <Tooltip label={label}>
          <span>{icon}</span>
        </Tooltip>
      )}
    </UnstyledButton>
  );
};

const LogoSection = ({ collapsed }: { collapsed: boolean }) => {
  const { classes, cx } = useStyles();
  const rows = (
    <Table className={classes.logoSectionTable}>
      {collapsed ? (
        <tbody>
          <tr>
            <td>
              <Image src={Logo} width="50px" className={classes.logo} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td>
              <Image src={Logo} width="50px" className={classes.logo} />
            </td>
            <td>
              <span className={cx(classes.companyName)}>
                {process.env.REACT_APP_COMPANY_NAME || envVariables.REACT_APP_COMPANY_NAME}
              </span>
            </td>
          </tr>
        </tbody>
      )}
    </Table>
  );

  return <Grid>{rows}</Grid>;
};

const SideMenu = ({
  menuItems,
  handleNavigation,
  active,
  setActive,
  username,
  avatarAlt,
  logout,
  avatarUrl,
}: ISideNavProps) => {
  const { classes } = useStyles();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const theme = useMantineTheme();

  const links = useMemo(
    () =>
      menuItems.map((link, index) => (
        <WithFeatureAuthorization
          authType="menu"
          featureId={link.feature}
          key={link.label}
        >
          <NavbarLink
            {...link}
            active={index === active}
            onClick={() => handleNavigation(link.path, index)}
            collapsed={collapsed}
          />
        </WithFeatureAuthorization>
      )),
    [active, collapsed]
  );

  const baseNavWidth = collapsed ? 80 : 220;

  useEffect(() => {
    const currentMenuIndex = menuItems.findIndex(
      (menu) => menu.path === window.location.pathname
    );
    setActive(currentMenuIndex);
  }, [window.location.pathname]);

  return (
    <>
      <Navbar
        data-testid="side-navigation-component"
        fixed={true}
        p="md"
        width={{ base: baseNavWidth }}
        className={collapsed ? classes.nav : classes.collapseNav}
      >
        <Navbar.Section>
          <LogoSection collapsed={collapsed} />
        </Navbar.Section>
        <Navbar.Section mt={20}>
          <Stack
            justify="center"
            spacing={0}
            className={
              collapsed ? classes.collapseIconCollapsed : classes.collapseIcon
            }
          >
            <CollapseIcon
              onClick={() => setCollapsed((prev) => !prev)}
              collapsed={collapsed}
            />
            <DividerComponent className={classes.divider} />
          </Stack>
        </Navbar.Section>
        <Navbar.Section grow mt={10}>
          <Stack
            justify="center"
            spacing={0}
            className={collapsed ? "" : classes.menuStack}
          >
            {links}
          </Stack>
        </Navbar.Section>

        <Navbar.Section>
          <Stack justify="center" spacing={0}>
            <Flex direction="row" align={"center"}>
              <Tooltip label={username} disabled={!collapsed}>
                <span
                  className={
                    collapsed ? classes.userAvatarCollapsed : classes.userAvatar
                  }
                >
                  <Avatar
                    type={AvatarTypes.default}
                    color={theme.colors.cyan[1]}
                    radius="xl"
                    src={avatarUrl}
                    size={35}
                  >
                    {avatarAlt}
                  </Avatar>
                </span>
              </Tooltip>
              {!collapsed ? (
                <Typography
                  type={TypographyTypes.default}
                  text={username}
                  className={classes.userName}
                />
              ) : null}
            </Flex>
          </Stack>
        </Navbar.Section>

        <Navbar.Section mt={0} className={classes.logoutSection} onClick={() => logout()}>
          <Stack justify="center" spacing={0}>
            <Flex direction="row" align={"center"}>
              <Tooltip label={"Logout"} disabled={!collapsed}>
                <span className={classes.logoutIcon}>
                  <ActionIcon>
                    <IconLogout size="30px" />
                  </ActionIcon>
                </span>
              </Tooltip>
              {!collapsed ? (
                <Typography
                  type={TypographyTypes.default}
                  text={"Logout"}
                  className={classes.userName}
                />
              ) : null}
            </Flex>
          </Stack>
        </Navbar.Section>
      </Navbar>

      <Collapse in={true}>
        <Skeleton height={140} radius="md" animate={true} />
      </Collapse>
    </>
  );
};

export default SideMenu;
