export const tabs = [
	"Initiation",
	"Project Management",
	"Requirements",
	"Technical",
	"Quality",
	"Production",
	"Project Closure",
];

const processName = [
	"M2C1",
	"Internal Kick Off",
	"Client Kick Off",
	"Communication Plan",
	"RACI",
	"Project Plan",
	"Project Tracking",
];

export const frequency = ["Weekly", "Monthly", "Quarterly", "Yearly"];

// 5 roles
export const roles = ["Administrator", "Analyst", "Architect", "Auditor", "Business Analyst"];

const randomRoleGen = () => {
	const number = Math.floor(Math.random() * 5);
	const role = roles[number] ?? "Administrator";
	return role;
};
// 5 tools (cv, excel, word, powerpoint, etc)
export const tools = ["cv", "excel", "word", "powerpoint", "etc"];

const randomToolGen = () => {
	const number = Math.floor(Math.random() * 5);
	const tool = tools[number] ?? "cv";
	return tool;
};

export const processPanel: any = [];

const randomBooleanGen = () => {
	const number = Math.floor(Math.random() * 2);
	if (number === 0) {
		return false;
	}
	return true;
};

// Randomly Weekly, Monthly, Quarterly, Yearly
const randomFrequency = () => {
	const number = Math.floor(Math.random() * 4);
	const selectedFr = frequency[number] ?? "Weekly";
	return selectedFr;
};

// Random number generator from x to y
const randomNumGen = (x: number, y: number) => {
	const number = Math.floor(Math.random() * (y - x + 1) + x);
	return number;
};

export const owners = [
	{ name: "Mohamed Suhail", avatar: "https://avatars.githubusercontent.com/u/48148796?v=4" },
	{ name: "Chandana", avatar: "https://avatars.githubusercontent.com/u/47012464?v=4" },
	{
		name: "Saranya",
		avatar: "https://avatars.githubusercontent.com/u/47090064?v=4",
	},
	{ name: "Saravanan", avatar: "https://avatars.githubusercontent.com/u/47092564?v=4" },
	{ name: "Chathuni Wijesinghe", avatar: "https://avatars.githubusercontent.com/u/47152464?v=4" },
	{ name: "Nilfa Sahilan", avatar: "https://avatars.githubusercontent.com/u/47092364?v=4" },
	{ name: "Isuru Madumadawa", avatar: "https://avatars.githubusercontent.com/u/47097864?v=4" },
];

const randomOwnerGen = () => {
	const number = Math.floor(Math.random() * 7);
	const owner = owners[number].name ?? "Mohamed Suhail";
	return owner;
};

processName.forEach((name) => {
	processPanel.push([
		name,
		// Randomly boolean
		randomBooleanGen(),
		randomNumGen(1,3),
		// Randomly tool (cv, excel, word, powerpoint, etc)
		randomNumGen(1,5),
		// Randomly roles
		randomRoleGen(),
		randomOwnerGen(),

		" Lorem ipsum dolor sit amet consectetur. ",
	]);
});

export default processPanel;
