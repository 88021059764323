import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { MantineProvider } from "@mantine/core";
import { defaultTheme } from "./data/theme/theme";
import "./localization/i18n";
import "./index.css";
import { NotificationsProvider } from "@mantine/notifications";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./services/authService";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
    <MantineProvider theme={defaultTheme} withGlobalStyles withNormalizeCSS>
      <NotificationsProvider position="top-right" zIndex={2077}>
        <Provider store={store}>
          <App />
        </Provider>
      </NotificationsProvider>
    </MantineProvider>
  </MsalProvider>
);

reportWebVitals();
