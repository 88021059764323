import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_IDENTIFIER } from "../../constants/config";
import { getAccessToken } from "../../services/authService";
import {
  getProcessPanelService,
  IProcessPanelService,
} from "../../services/processService";
import { IProcessPanelDetails } from "../../utils/@types/processPanel";

export interface processPanelState {
  data: IProcessPanelDetails | undefined;
  status: "idle" | "loading" | "failed" | "success";
}

const initialState: processPanelState = {
  data: undefined,
  status: "idle",
};

interface IProcessPanelParameters {
  projectId: string;
  methodologyId?: string;
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
}

export const getProcessPanel = createAsyncThunk(
  "process/getProcessPanel",
  async (params: IProcessPanelParameters) => {
    const accessToken = await getAccessToken(
      params.instance,
      params.accounts[0]
    );
    const response = await getProcessPanelService({
      projectId: params.projectId,
      methodologyId: params.methodologyId,
      accessToken,
    });
    return response.data;
  }
);

export const processPanelSlice = createSlice({
  name: "processPanel",
  initialState,
  reducers: {
    setProcesses: (state, action: PayloadAction<IProcessPanelDetails>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessPanel.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProcessPanel.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
        // store the data in local storage
        localStorage.setItem(LOCAL_STORAGE_IDENTIFIER.processPanel, JSON.stringify(action.payload));
      })
      .addCase(getProcessPanel.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setProcesses } = processPanelSlice.actions;

export const selectProcessPanel = (state: {
  processPanel: processPanelState;
}) => state.processPanel;

export default processPanelSlice.reducer;
