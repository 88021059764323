import axios, { AxiosInstance } from "axios";
import AxiosMockAdapter from "axios-mock-adapter";
import { mockGetTailoringHeader } from "../__Mock__/tailoringHeader";
import {
  mockGetProcessTable,
  mockGetFrequency,
  mockGetTools,
} from "../__Mock__/Process";
import { mockGetStatusesMasterData } from "../__Mock__/materData";
import {
  mockGetAllProjectsList,
  mockGetMyProjectsList,
  mockGetPendingApprovalList
} from "../__Mock__/projects";
import {
  mockGetUserDetails,
  mockGetUserFeatures,
  mockGetUserImage,
} from "../__Mock__/user";
import { mockGetMethodology } from "../__Mock__/methodology";
import { mockGetProjectInitiator } from "../__Mock__/initiator";
import { mockGetProjectReviewer } from "../__Mock__/reviewer";
import envVariables from "../utils/config.json";

const axiosApiInstance: AxiosInstance = axios.create();
const axiosMockApiInstance: AxiosInstance = axios.create();

const isAxiosMock = (process.env.REACT_APP_IS_AXIOS_MOCK || envVariables.REACT_APP_IS_AXIOS_MOCK) === "true"; 

export const axiosMockAdapterInstance = new AxiosMockAdapter(
  axiosMockApiInstance,
  { delayResponse: 1 }
);

if (isAxiosMock) {
  mockGetUserDetails();
  mockGetTailoringHeader();
  mockGetMyProjectsList();
  mockGetAllProjectsList();
  mockGetPendingApprovalList();
  mockGetUserImage();
  mockGetMethodology();
  mockGetStatusesMasterData();
  mockGetUserFeatures();
  mockGetProcessTable(axiosMockAdapterInstance);
  mockGetFrequency(axiosMockAdapterInstance);
  mockGetTools(axiosMockAdapterInstance);
  mockGetProjectInitiator();
  mockGetProjectReviewer();
}

export default isAxiosMock
  ? axiosMockApiInstance
  : axiosApiInstance;
