import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX, IconInfoCircle } from "@tabler/icons";

export const successShowNotification = (title: string, message: string) => {
  showNotification({
    id: "",
    title: title,
    message: message,
    icon: <IconCheck data-testid='success-icon' />,
    disallowClose: false,
    autoClose: 5000,
    color: "green",
    className: "",
    loading: false,
  });
};

export const errorShowNotification = (title: string, message: string) => {
  showNotification({
    id: "",
    title: title,
    message: message,
    icon: <IconX data-testid='error-icon' />,
    disallowClose: false,
    autoClose: 5000,
    color: "red",
    className: "",
    loading: false,
  });
};

// information notification
export const infoShowNotification = (title: string, message: string) => {
  showNotification({
    id: "",
    title: title,
    message: message,
    icon: <IconInfoCircle data-testid='info-icon' />,
    disallowClose: false,
    autoClose: 5000,
    color: "blue",
    className: "",
    loading: false,
  });
};
