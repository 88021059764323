import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getProjectAllocationsService } from "../../services/processService";
import { IProjectAllocation } from "../../utils/@types/projectAllocation";

export interface IProjectAllocationState {
	data: IProjectAllocation[];
	status: "idle" | "loading" | "failed" | "success";
}

const initialState: IProjectAllocationState = {
	data: [],
	status: "idle",
};

interface IGetProjectAllocations {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
	projectId: string;
}

export const getProjectAllocations = createAsyncThunk(
	"projectAllocation/getProjectAllocations",
	async (params: IGetProjectAllocations) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
		const response = await getProjectAllocationsService(params.projectId, accessToken);
		return response.data;
	}
);

export const projectAllocationSlice = createSlice({
  name: "projectAllocation",
  initialState,
  reducers: {
    resetProjectAllocation: (state) => {
      state.data = [];
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectAllocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProjectAllocations.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getProjectAllocations.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const selectProjectAllocation = (state: { projectAllocation: IProjectAllocationState }) => state.projectAllocation;

export const { resetProjectAllocation } = projectAllocationSlice.actions;

export default projectAllocationSlice.reducer;
