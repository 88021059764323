import {
  UPDATE_USER_ROLES,
  USER_FEATURES,
  USER_ROLES,
} from "../../constants/api-paths";
import axios from "../interceptors";
import { log } from "./../../utils/functions/index";
import {
  graphConfig,
  authRequest,
  commonRequest,
  msalConfig,
} from "./authConfig";
import Login from "./Login";
import Logout from "./Logout";

import envVariables from "../../utils/config.json";

const backendUrl = process.env.REACT_APP_AUTH_BASE_URL || envVariables.REACT_APP_AUTH_BASE_URL;
const reactAppName = process.env.REACT_APP_NAME || envVariables.REACT_APP_NAME;

const getAccessToken = async (
  instance: {
    acquireTokenSilent: (arg0: { account: any; scopes: string[] }) => any;
  },
  account: any
) => {
  const response = await instance.acquireTokenSilent({
    ...commonRequest,
    account,
  });

  return response.accessToken;
};

const getAuthAccessToken = async (
  instance: {
    acquireTokenSilent: (arg0: { account: any; scopes: string[] }) => any;
  },
  account: any
) => {
  const response = await instance.acquireTokenSilent({
    ...authRequest,
    account,
  });

  return response.accessToken;
};

const getUserDetails = async (accessToken: any) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: graphConfig.graphMeEndpoint,
    headers: { authorization: bearer },
  });
};

const getUserImage = async (accessToken: any) => {
  try {
    const bearer = `Bearer ${accessToken}`;

    const response = await axios({
      method: "GET",
      url: graphConfig.graphMeImageEndpoint,
      headers: { authorization: bearer },
      responseType: "blob",
    });

    const blob = await response.data;

    const imageURL = URL.createObjectURL(blob);
    return { data: imageURL };
  } catch (error) {
    log(error);
  }
};

const getUserFeatures = async (accessToken: any) => {
  const bearer = `Bearer ${accessToken}`;

  return axios({
    method: "GET",
    url: `${backendUrl}${USER_FEATURES}`,
    headers: {
      authorization: bearer,
      Application: reactAppName,
    },
  });
};

const getUserRoles = async (accessToken: any) => {
  const bearer = `Bearer ${accessToken}`;

  return axios({
    method: "GET",
    url: `${backendUrl}${USER_ROLES}`,
    headers: {
      authorization: bearer,
      Application: reactAppName,
    },
  });
};

const updateUserRole = async (
  accessToken: any,
  body: { UserId: number; RoleId: number }
) => {
  const bearer = `Bearer ${accessToken}`;

  return axios({
    method: "PUT",
    url: `${backendUrl}${UPDATE_USER_ROLES}`,
    headers: {
      authorization: bearer,
      Application: reactAppName,
    },
    data: body,
  });
};

export {
  msalConfig,
  graphConfig,
  authRequest,
  commonRequest,
  Login,
  Logout,
  getUserDetails,
  getAccessToken,
  getAuthAccessToken,
  getUserImage,
  getUserFeatures,
  getUserRoles,
  updateUserRole,
};
