const USER_DETAILS = "/me";
const USER_IMAGE = "/me/photos/48x48/$value";
const PROCESS_PANEL = (projectId: string, methodologyId: string) =>
  `/tailoring/project-tailoring/${projectId}/${methodologyId}/tailored-processes`;
const PROCESS_PANEL_HISTORY = (tailoringProcessId: string) =>
  `/tailoring/project-tailoring/tailoring-process/${tailoringProcessId}/history`;
const FREQUENCY = "/tailoring/master-data/frequencies";
const TOOLS = "/tailoring/master-data/tools";
const MY_PROJECTS_LIST = "/tailoring/projects/my";
const ALL_PROJECTS_LIST = "/tailoring/projects/all";
const PROJECT_TAILORING_HEADER = "/tailoring/project-tailoring";
const METHODOLOGY_LIST = "/tailoring/master-data/methodologies";
const PROJECT_INITIATORS = `/tailoring/projects`;
const PROJECT_REVIEWERS = "/tailoring/user/users?Type=Reviewer";
const PENDING_APPROVAL_LIST = "/tailoring/projects/pending";
const STATUS_LIST = "/tailoring/master-data/tailoring-status";
const INITIATOR_LIST = "/master-data/initiators";
const USER_FEATURES = "/auth/user/auth-details";
const USER_ROLES = "/auth/role/roles";
const UPDATE_USER_ROLES = "/auth/user/update-role";
const PROJECT_ALLOCATIONS = (projectId: string) =>
  `/tailoring/projects/${projectId}/allocations`;
const PROJECT_ROLES = "/tailoring/master-data/project-roles";
const ALLOCATIONS = (projectId: string) =>
  `/tailoring/projects/${projectId}/allocations`;
const PROJECT_TAILORING_VERSIONS = (tailoringProcessId: string) =>
  `/tailoring/project-tailoring/tailoring-process/${tailoringProcessId}/versions`;
const PROCESS_SAMPLES = (processMasterId: string) =>
  `/tailoring/process-master/${processMasterId}/templates`;
const GET_AND_CREATE_REFERENCE = (tailoringProcessId: string) =>
  `/tailoring/project-tailoring/${tailoringProcessId}/tailored-processes-reference`;
const DELETE_REFERENCE = (tailoredProcessReferenceId: string) =>
  `/tailoring/project-tailoring/${tailoredProcessReferenceId}/delete-reference`;
  const UPDATE_REFERENCE = (tailoredProcessReferenceId: string) =>
  `/tailoring/project-tailoring/${tailoredProcessReferenceId}/update-reference`;
const SAVE_SUBMIT_TAILORING = (tailoringRecordId: number) => `/tailoring/project-tailoring/${tailoringRecordId.toString()}/processes-tailoring`;
const ADD_TAILORING_PROCESS_NOTE = '/tailoring/project-tailoring/tailored-process-notes';
const GET_TAILORING_PROCESS_NOTES = (tailoredProcessId: number) => `/tailoring/project-tailoring/${tailoredProcessId.toString()}/tailored-process-notes`;
const DELETE_TAILORING_PROCESS_NOTES =  (tailoredProcessNoteId: string, projectId: string) =>
`/tailoring/project-tailoring/${projectId}/${tailoredProcessNoteId}/tailored-process-notes`;

export {
  USER_DETAILS,
  USER_IMAGE,
  PROJECT_TAILORING_HEADER,
  PROCESS_PANEL,
  PROCESS_PANEL_HISTORY,
  FREQUENCY,
  TOOLS,
  MY_PROJECTS_LIST,
  ALL_PROJECTS_LIST,
  STATUS_LIST,
  INITIATOR_LIST,
  METHODOLOGY_LIST,
  USER_FEATURES,
  USER_ROLES,
  UPDATE_USER_ROLES,
  PROJECT_INITIATORS,
  PROJECT_REVIEWERS,
  PENDING_APPROVAL_LIST,
  PROJECT_ALLOCATIONS,
  PROJECT_ROLES,
  ALLOCATIONS,
  PROCESS_SAMPLES,
  PROJECT_TAILORING_VERSIONS,
  GET_AND_CREATE_REFERENCE,
  DELETE_REFERENCE,
  UPDATE_REFERENCE,
  SAVE_SUBMIT_TAILORING,
  ADD_TAILORING_PROCESS_NOTE,
  GET_TAILORING_PROCESS_NOTES,
  DELETE_TAILORING_PROCESS_NOTES
};
