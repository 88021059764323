import {
  STATUS_LIST,
  INITIATOR_LIST,
  METHODOLOGY_LIST,
  PROJECT_REVIEWERS,
  PROJECT_INITIATORS,
} from "../../constants/api-paths";

import envVariables from "../../utils/config.json";

import axios from "../interceptors";

const backendUrl = process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL;

const getStatusesMasterData = async (accessToken: string) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${backendUrl}${STATUS_LIST}`,
    headers: { authorization: bearer },
  });
};

const getInitiators = async (accessToken: string) => {
  const bearer = `Bearer ${accessToken}`;

  return axios({
    method: "GET",
    url: `${backendUrl}${INITIATOR_LIST}`,
    headers: { authorization: bearer },
  });
};

interface methodologyParams {
  accessToken: string;
}

const getMethodology = async (params: methodologyParams) => {
  const bearer = `Bearer ${params.accessToken}`;
  return axios({
    method: "GET",
    url: `${backendUrl}${METHODOLOGY_LIST}`,
    headers: { authorization: bearer },
  });
};

interface initiatorParams {
  accessToken: string;
  query?:string;
}

const getProjectInitiator = async (params: initiatorParams) => {
  const bearer = `Bearer ${params.accessToken}`;
  const projectId=params.query;
  return axios({
    method: "GET",
    url: `${backendUrl}${PROJECT_INITIATORS}/${projectId}/allocations`,
    headers: { authorization: bearer },
  });
};

interface reviewerParams {
  accessToken: string;
  query?:string
}

const getProjectReviewer = async (params: reviewerParams) => {
  const bearer = `Bearer ${params.accessToken}`;
  const projectId=params.query;
  return axios({
    method: "GET",
    url: `${backendUrl}${PROJECT_REVIEWERS}`,
    headers: { authorization: bearer },
  });
};

export {
  getStatusesMasterData,
  getInitiators,
  getMethodology,
  getProjectReviewer,
  getProjectInitiator,
};
