import React, { Suspense } from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router";

const UnderDevelopment = React.lazy(() => import("../pages/UnderDevelopment"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const AllProjects = React.lazy(() => import("../pages/AllProjects"));
const MyProjects = React.lazy(() => import("../pages/MyProjects"));
const TailoringView = React.lazy(() => import("../pages/TailoringView"));
const PendingProjects = React.lazy(() => import("../pages/PendingProjects"));

const Navigations = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<MyProjects />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/all-projects" element={<AllProjects />} />
        <Route path="/pending-approval" element={<PendingProjects />} />
        <Route path="/under-development" element={<UnderDevelopment />} />
        <Route path="/tailoring-view/:projectId" element={<TailoringView />} />
      </Routes>
    </Suspense>
  );
};

export default Navigations;
