import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getProjectReviewer as getProjectReviewerService } from "../../services/materDataService";
import { RootState } from "../store";

export interface projectReviewerState {
  status: "idle" | "loading" | "failed" | "success";
  data: [{ id: number; name: string }]|[];
}

interface reviewerParams {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  query?: string;
}

const initialState: projectReviewerState = {
  status: "idle",
  data:[]
  
};

export const getReviewer = createAsyncThunk(
  `reviewer/getReviewer`,
  async (params: reviewerParams) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getProjectReviewerService({accessToken, query: params.query});
    const data = response.data;
    return {
      data,
    };
  }
);

export const reviewerSlice = createSlice({
  name: "reviewer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReviewer.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.data ? action.payload.data : [];
      })
      .addCase(getReviewer.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectReviewer = (state: RootState) => state.reviewer;

export default reviewerSlice.reducer;
