import { ALL_PROJECTS_LIST, MY_PROJECTS_LIST, PENDING_APPROVAL_LIST } from "../../constants/api-paths";
import axios from "../interceptors";
import envVariables from "../../utils/config.json";

interface projectListParameters {
  accessToken: string;
  query: string;
}

const backendUrl = process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL;

const getAllProjectsList = async (params: projectListParameters) => {
  const bearer = `Bearer ${params.accessToken}`;

  return axios({
    method: "GET",
    url: `${backendUrl}${ALL_PROJECTS_LIST}${params.query}`,
    headers: { authorization: bearer, Application: "Tailoring" },
  });
};

const getMyProjectsList = async (params: projectListParameters) => {
  const bearer = `Bearer ${params.accessToken}`;

  return axios({
    method: "GET",
    url: `${backendUrl}${MY_PROJECTS_LIST}${params.query}`,
    headers: { authorization: bearer, Application: "Tailoring" },
  });
};

const getPendingApprovalList = async (params:projectListParameters) => {

  const bearer = `Bearer ${params.accessToken}`;

  return axios({
    method: "GET",
    url: `${backendUrl}${PENDING_APPROVAL_LIST}${params.query}`,
    headers: { authorization: bearer, "Application": "Tailoring" },
  });
};

export { getAllProjectsList, getMyProjectsList, getPendingApprovalList };
