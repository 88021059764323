import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ITailoringManagementState {
  status: "idle" | "success" | "error";
  navModalStatus: boolean;
}

const initialState: ITailoringManagementState = {
  status: "idle",
  navModalStatus: false,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setNavModalStatus: (state, action: PayloadAction<boolean>) => {
      state.navModalStatus = action.payload;
    }
  },
  extraReducers: (builder) => {},
});

export const { setNavModalStatus } = navigationSlice.actions;

export const selectNavModalStatus = (state: RootState) => state.navigation.navModalStatus;

export default navigationSlice.reducer;
