import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { METHODOLOGY_LIST } from "../../constants/api-paths";
import { getAccessToken } from "../../services/authService";
import { getMethodology as getMethodologyService } from "../../services/materDataService";
import { RootState } from "../store";

export interface methodologyState {
  status: "idle" | "loading" | "failed" | "success";
  data: [{ id: number; name: string }] | [];
}

interface methodologyParams {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  query?: string;
}

const initialState: methodologyState = {
  status: "idle",
  data: [],
};

export const getMethodology = createAsyncThunk(
  `methodology/getMethodology`,
  async (params: methodologyParams) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getMethodologyService({accessToken});

    const data = response.data;
    return {
      data,
    };
  }
);

export const methodologySlice = createSlice({
  name: "methodology",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMethodology.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMethodology.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.data ? action.payload.data : [];
      })
      .addCase(getMethodology.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectMethodology = (state: RootState) => state.methodology;

export default methodologySlice.reducer;
