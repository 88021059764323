import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getProjectRolesService } from "../../services/processService";

export interface IProjectRoleState {
	data: { id: number; name: string }[];
	status: "idle" | "loading" | "failed" | "success";
}

const initialState: IProjectRoleState = {
	data: [],
	status: "idle",
};

interface IProjectRolesParameters {
	instance: IPublicClientApplication;
	accounts: AccountInfo[];
}

export const getProjectRoles = createAsyncThunk("projectRole/getProjectRoles", async (params: IProjectRolesParameters) => {
	const accessToken = await getAccessToken(params.instance, params.accounts[0]);
	const response = await getProjectRolesService(accessToken);
	return response.data;
});

export const projectRoleSlice = createSlice({
	name: "projectRole",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getProjectRoles.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getProjectRoles.fulfilled, (state, action) => {
				state.status = "success";
				state.data = action.payload;
			})
			.addCase(getProjectRoles.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export const selectProjectRole = (state: { projectRole: IProjectRoleState }) => state.projectRole;

export default projectRoleSlice.reducer;
