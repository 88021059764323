import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getFrequencyService } from "../../services/processService";

export interface frequencyState {
	data: { id: number; name: string }[];
	status: "idle" | "loading" | "failed" | "success";
}

const initialState: frequencyState = {
	data: [],
	status: "idle",
};

interface IFrequencyParameters{
	instance: IPublicClientApplication;
	accounts: AccountInfo[];
}

export const getFrequency = createAsyncThunk("frequency/getFrequency", async (params:IFrequencyParameters) => {
	const accessToken = await getAccessToken(params.instance, params.accounts[0]);
	const response = await getFrequencyService(accessToken);
	return response.data;
});

export const frequencySlice = createSlice({
	name: "frequency",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getFrequency.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getFrequency.fulfilled, (state, action) => {
				state.status = "success";
				state.data = action.payload;
			})
			.addCase(getFrequency.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export const selectFrequency = (state: { frequency: frequencyState }) => state.frequency;

export default frequencySlice.reducer;
