import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { deleteTailoredProcessNoteService } from "../../services/tailoringService"
import {
  errorShowNotification,
  successShowNotification,
} from "../../components/common/Toast";
import i18n from 'i18next'

interface IReferenceDeleteHeaderParameters {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  tailoredProcessNoteId: string;
  projectId: string;
}

export const deleteNote = createAsyncThunk(
  `notes/deleteNotes`,
  async (params: IReferenceDeleteHeaderParameters) => {
    const accessToken = await getAccessToken(
      params.instance,
      params.accounts[0]
    );
    const response = await deleteTailoredProcessNoteService({
      accessToken,
      tailoredProcessNoteId: params.tailoredProcessNoteId,
      projectId: params.projectId
    });

    return response.data;
  }
);

export interface noteState {
  status: "delete_idle" | "delete_loading" | "delete_succeed" | "delete_failed";
  data: any[];
  isDeleting: Boolean;
}

const initialState: noteState = {
  status: "delete_idle",
  data: [],
  isDeleting: false,
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteNote.pending, (state) => {
        state.status = "delete_loading";
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.status = "delete_idle";
        successShowNotification(
            i18n.t("delete_note"),
            i18n.t("delete_note_success")
          );
      })
      .addCase(deleteNote.rejected, (state) => {
        errorShowNotification(
            i18n.t("something_went_wrong"),
            i18n.t("something_went_wrong_content")
          );
        state.status = "delete_failed";
      });
  },
});

export default notesSlice.reducer;