export interface ISaveSubmitProjectTailorParams {
	projectId?: number | null;
	saveType?: ITailoringSaveType;
	methodologyId?: number | null;
	methodologyVersion: string;
	initiatorId?: number | null;
	reviewerId?: number | null;
	initiatedDate: Date;
	tailoredProcesses: ITailoredProcess[];
}

export interface ITailoredProcess {
	id: number;
	processMasterId: number;
	processAreaName: string;
	name: string;
	processToolTip: string;
	isApplicable: boolean;
	isApplicableMandatory: boolean;
	frequencyId: number | null;
	tools: number[];
	roleId: number;
	ownerId: number;
	initiatorNote: string;
	approveNote: string;
	processAreaOrderIndex: number;
	processOrderIndex: number;
}

export enum ITailoringSaveType {
	Save = 1,
	Submit = 2,
	NeedInformation = 3,
}

export type TailoringSaveType = "Save" | "Submit" | "NeedInformation"; 
