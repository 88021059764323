import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import {
  getTailoringVersions as getTailoringVersionsService,
  ITailoringHistoryHeaderParams,
} from "../../services/tailoringService";
import { RootState } from "../store";

export interface ITailoringVersion {
  versionNumber: string;
  savedDate: string;
}

export interface tailoringVersionsState {
  status: "idle" | "loading" | "failed";
  data: ITailoringVersion[];
}

const initialState: tailoringVersionsState = {
  status: "idle",
  data: [],
};

interface ITailoringHistoryHeaderParameters {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  tailoringProcessId: string;
}

export const getTailoringVersions = createAsyncThunk(
  `tailoringVersions/getTailoringVersions`,
  async (params: ITailoringHistoryHeaderParameters) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getTailoringVersionsService({accessToken, tailoringProcessId: params.tailoringProcessId});
    return response.data;
  }
);

export const tailoringVersionsSlice = createSlice({
  name: "tailoringVersions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTailoringVersions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTailoringVersions.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload
          ? action.payload.length > 0
            ? action.payload
            : []
          : [];
      })
      .addCase(getTailoringVersions.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectTailoringVersions = (state: RootState) =>
  state.tailoringVersions;

export default tailoringVersionsSlice.reducer;
