export enum AnchorTypes {
  text,
  tooltipAfterText,
}

export enum DropdownTypes {
  default,
  withIcon,
}

export enum AlertTypes {
  error,
  success,
}

export enum TagTypes {
  userStatus,
  projectStatus,
}

export enum NotificationTypes {
  default,
  error,
  success,
  upload,
}

export enum MainButtonTypes {
  default,
  defaultOutline,
  outline,
  underlined,
  underlined2,
}

export enum TypographyTypes {
  default,
  defaultUnderlined,
  defaultBold,
  mainHeading,
  tools,
}

export enum AvatarTypes {
  default,
  withText,
}

export enum TailoringSaveType {
  Save = 1,
  Submit = 2,
  NeedInformation = 3,
}
