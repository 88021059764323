import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { ITailoredProcessNoteParams, updateTailoredProcessNoteService } from "../../services/tailoringService";

export interface updateTailoredProcessNoteState {
  status: "idle" | "loading" | "failed" | "success";
}

const initialState: updateTailoredProcessNoteState = {
  status: "idle",
};

export const updateTailoredProcessNote = createAsyncThunk(
  "tailoredProcessNote/updateTailoredProcessNote",
  async (params: { instance: IPublicClientApplication; accounts: AccountInfo[]; data: ITailoredProcessNoteParams &{id:string} }) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await updateTailoredProcessNoteService({
      accessToken,
      ...params.data,
    });
    return response.data;
  });

export const updateTailoredProcessNoteSlice = createSlice({
  name: "updateTailoredProcessNote",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTailoredProcessNote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTailoredProcessNote.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(updateTailoredProcessNote.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const selectUpdateTailoredProcessNote = (state: { updateTailoredProcessNote: updateTailoredProcessNoteState }) =>
  state.updateTailoredProcessNote;

export default updateTailoredProcessNoteSlice.reducer;
