import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import userReducer from "./slices/userSlice";
import tailoringHeaderReducer from "./slices/tailoringHeaderSlice";
import processPanel from "./slices/processPanelSlice";
import frequency from "./slices/frequencySlice";
import tool from "./slices/toolSlice";
import projectListReducer from "./slices/projectListSlice";
import methodologyReducer from "./slices/methodologySlice";
import masterDataReducer from "./slices/masterDataSlice";
import initiatorReducer from "./slices/initiatorSlice";
import reviewerReducer from "./slices/reviewerSlice";
import projectRolesReducer from "./slices/projectRoleSlice";
import projectAllocationReducer from "./slices/projectAllocationSlice";
import tailoringHistoryReducer from "./slices/tailoringHistorySlice";
import processSampleReducer from "./slices/processSampleSlice";
import tailoringManagementReducer from "./slices/tailoringManagementSlice";
import tailoringVersionsReducer from "./slices/tailoringVersionsSlice";
import referenceReducer from "./slices/referenceSlice"; 
import projectTailorSaveSubmitReducer from "./slices/projectTailorSaveSubmitSlice"; 
import navigationReducer from "./slices/navigationSlice"; 
import addTailoredProcessNoteReducer from "./slices/addTailoredProcessNoteSlice";
import updateTailoredProcessNoteReducer from "./slices/updateTailoredProcessNoteSlice";
import processNoteReducer from "./slices/processNoteSlice";
import deleteTailoredProcessNote from "./slices/deleteTailoredProcessNoteSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    tailoringHeader: tailoringHeaderReducer,
    methodology: methodologyReducer,
    projectList: projectListReducer,
    masterData: masterDataReducer,
    processPanel: processPanel,
    frequency: frequency,
    tool: tool,
    initiator: initiatorReducer,
    reviewer: reviewerReducer,
    projectRoles: projectRolesReducer,
    projectAllocation: projectAllocationReducer,
    tailoringHistory: tailoringHistoryReducer,
    processSample: processSampleReducer,
    tailoringManagement: tailoringManagementReducer,
    tailoringVersions: tailoringVersionsReducer,
    reference: referenceReducer,
    projectTailorSaveSubmit: projectTailorSaveSubmitReducer,
    navigation: navigationReducer,
    addTailoredProcessNote: addTailoredProcessNoteReducer,
    updateTailoredProcessNote: updateTailoredProcessNoteReducer,
    tailoredProcessNotes: processNoteReducer,
    deleteTailoredProcessNote: deleteTailoredProcessNote,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
