import {
  GET_AND_CREATE_REFERENCE,
  DELETE_REFERENCE,
  UPDATE_REFERENCE,
} from "../../constants/api-paths";
import axios from "../interceptors";
import envVariables from "../../utils/config.json";
import { Url } from "url";

const backendUrl = process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL;

export interface IReferenceHeaderParams {
  accessToken: string;
  tailoringProcessId: string;
}

export interface IReferenceCreateHeaderParams {
  accessToken: string;
  tailoringProcessId: string;
  body: {
    url: string;
    comment: string;
  };
}

export interface IReferenceUpdateHeaderParams {
  accessToken: string;
  tailoredProcessReferenceId: string;
  tailoringProcessId: string;
  body: {
    url: string;
    comment: string;
  };
}

export interface IReferenceDeleteHeaderParams {
  accessToken: string;
  tailoredProcessReferenceId: string;
  tailoringProcessId: string;
}

const getReferences = async ({
  accessToken,
  tailoringProcessId,
}: IReferenceHeaderParams) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${backendUrl}${GET_AND_CREATE_REFERENCE(
      tailoringProcessId
    )}`,
    headers: { authorization: bearer },
  });
};

const createReference = async ({
  accessToken,
  tailoringProcessId,
  body,
}: IReferenceCreateHeaderParams) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "POST",
    url: `${backendUrl}${GET_AND_CREATE_REFERENCE(
      tailoringProcessId
    )}`,
    headers: { authorization: bearer },
    data: body,
  });
};

const updateReference = async ({
  accessToken,
  tailoredProcessReferenceId,
  body,
}: IReferenceUpdateHeaderParams) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "PUT",
    url: `${backendUrl}${UPDATE_REFERENCE(
      tailoredProcessReferenceId
    )}`,
    headers: { authorization: bearer },
    data: body,
  });
};

const deleteReference = async ({
  accessToken,
  tailoredProcessReferenceId,
}: IReferenceDeleteHeaderParams) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "DELETE",
    url: `${backendUrl}${DELETE_REFERENCE(
      tailoredProcessReferenceId
    )}`,
    headers: { authorization: bearer },
  });
};

export { getReferences, createReference, updateReference, deleteReference };
