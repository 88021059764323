import { AppShell } from "@mantine/core";
import Header from "../features/Header";
import SideNavigation from "../features/SideNavigation";
import Navigations from "../Navigations";

const AuthLayout = () => {
  return (
    <>
      <AppShell
        padding="md"
        layout="alt"
        navbar={<SideNavigation />}
        sx={{backgroundColor: "#F7F7F7"}}
      >
          <Navigations />
      </AppShell>
    </>
  );
};

export default AuthLayout;
