import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getProjectAllocations,
  getTailoringHeader as getTailoringHeaderService,
  IAllocationService,
} from "../../services/tailoringService";
import {
  StatusColors,
  TailoringStatuses,
} from "../../constants/tailoringStatuses";
import { RootState } from "../store";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getAccessToken } from "../../services/authService";

export interface tailoringHeaderState {
  status: "idle" | "loading" | "failed" | "success";
  tailoringStatus: TailoringStatuses;
  id: string;
  projectName: string;
  statusColor: string;
  statusId: number;
  type: string;
  methodologyId: string;
  initiatorId: string;
  reviewerId: string;
  allocations: ITeamParams[];
  projectId: number;
  methodologyVersion: string;
  tailoringVersion: string;
}

export interface ITeamParams {
  allocationStartDate?: string;
  allocationEndDate?: string;
  role: string;
  user: IUserParams;
}

interface IUserParams {
  id: string;
  name: string;
}

interface tailoringHeaderParams {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  query?: string;
}

interface IAllocationParameters {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  projectId: string;
}

const initialState: tailoringHeaderState = {
  status: "idle",
  tailoringStatus: "Tailoring Pending",
  id: "",
  projectName: "",
  statusColor: "",
  type: "TYPE",
  methodologyId: "",
  initiatorId: "",
  reviewerId: "",
  methodologyVersion: "",
  allocations: [],
  projectId: 0,
  statusId: 0,
  tailoringVersion: "0.0",
};

export const getTailoringHeader = createAsyncThunk(
  `tailoringHeader/getTailoringHeader`,
  async (params: tailoringHeaderParams) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getTailoringHeaderService({accessToken, query: params.query});
    const data = response.data;
    return {
      ...data,
    };
  }
);

export const getAllocations = createAsyncThunk(
  `tailoringHeader/getAllocations`,
  async (params: IAllocationParameters) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getProjectAllocations({accessToken, projectId: params.projectId});
    const data = response.data;
    return {
      data,
    };
  }
);

export const tailoringHeaderSlice = createSlice({
  name: "tailoringHeader",
  initialState,
  reducers: {
    setTailoringStatusId: (state, action: PayloadAction<string>) => {
      state.statusId = +action.payload;
    },
    setTailoringStatus: (state, action: PayloadAction<TailoringStatuses>) => {
      state.tailoringStatus = action.payload;
    },
    setTailoringStatusColor: (state, action: PayloadAction<StatusColors>) => {
      state.statusColor = action.payload;
    },
    setTailoringVersion: (state, action: PayloadAction<string>) => {
      state.tailoringVersion = action.payload;
    },
    setSelectedMethodology: (state, action: PayloadAction<string>) => {
      state.methodologyId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTailoringHeader.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTailoringHeader.fulfilled, (state, action) => {
        state.status = "success";
        state.id = action.payload.id ? action.payload.id : "";
        state.projectName = action.payload.projectName
          ? action.payload.projectName
          : "";
        state.tailoringStatus = action.payload.status
          ? action.payload.status
          : "";
        state.statusColor = action.payload.stausColor
          ? action.payload.stausColor
          : "";
        state.statusId = action.payload.statusId || 0;
        state.type = action.payload.type ? action.payload.type : "";
        state.methodologyId = action.payload.methodologyId
          ? action.payload.methodologyId
          : "";
        state.initiatorId = action.payload.initiatorId
          ? action.payload.initiatorId
          : "";
        state.reviewerId = action.payload.reviewerId
          ? action.payload.reviewerId
          : "";
        state.projectId = action.payload.projectId
          ? action.payload.projectId
          : "";
        state.methodologyVersion = action.payload.methodologyVersion;
        state.tailoringVersion = action.payload.version;
      })
      .addCase(getTailoringHeader.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAllocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllocations.fulfilled, (state, action) => {
        state.status = "success";
        state.allocations = action.payload.data
          ? action.payload.data.length > 0
            ? action.payload.data
            : []
          : [];
      })
      .addCase(getAllocations.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setTailoringStatus, setTailoringStatusColor, setTailoringVersion, setSelectedMethodology, setTailoringStatusId } =
  tailoringHeaderSlice.actions;

export const selectTailoringHeader = (state: RootState) =>
  state.tailoringHeader;
export const selectProjectStatus = (state: RootState) =>
  state.tailoringHeader.tailoringStatus;
export const selectTailoringVersion = (state: RootState) =>
  state.tailoringHeader.tailoringVersion;

export default tailoringHeaderSlice.reducer;
