import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  mainHeading: {
    fontWeight: 500,
    fontSize: "36px",
    lineHeight: "48px",
    color: theme.colors.dark[6],
  },

  default: {
    fontFamily: theme.fontFamily,
    lineHeight: "24px",
  },

  underlined: {
    textDecoration: "underline",
  },

  bold: {
    fontWeight: "bold",
  },

  tools: {
    background: theme.colors.gray[2],
    borderRadius: "5px",
    textAlign: "center",
    marginBottom: "5px",
  },
}));

export default useStyles;