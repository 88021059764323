import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getTailoredProcessNotesService } from "../../services/tailoringService";
import { RootState } from "../store";
import { TailoringStatuses } from "../../constants/tailoringStatuses";

export interface IProcessNoteData {
  id: number;
  status: TailoringStatuses;
  note: string;
  statusColor: string;
  createdBy: string;
  createdByUserId: number;
  createdOn: string;
  currentUserProjectRole: string;
  tailoredProcessId: number;
};

export interface IGetTailoredProcessNoteState {
  status: "idle" | "loading" | "failed" | "success";
  notes: IProcessNoteData[];
}

const initialState: IGetTailoredProcessNoteState = {
  status: "idle",
  notes: [],
};

export const getTailoredProcessNotes = createAsyncThunk(
  "tailoredProcessNote/getTailoredProcessNotes",
  async (params: { instance: IPublicClientApplication; accounts: AccountInfo[]; tailoredProcessId: number  }) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const tailoredProcessId = params.tailoredProcessId;
    const response = await getTailoredProcessNotesService({
      accessToken,
      tailoredProcessId
    });
    const data = response;
    return data;
  }
);

export const getTailoredProcessNotesSlice = createSlice({
  name: "getTailoredProcessNotes",
  initialState,
  reducers: {
    setNoteData: (state, action: PayloadAction<IProcessNoteData[]>) => {
      state.notes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTailoredProcessNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTailoredProcessNotes.fulfilled, (state, action) => {
        state.status = "idle";
        state.notes = action.payload.data
          ? action.payload.data.length > 0
            ? action.payload.data
            : []
          : [];
      })
      .addCase(getTailoredProcessNotes.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const { setNoteData } = getTailoredProcessNotesSlice.actions;

export const selectProcessNotes = (state: RootState) => state.tailoredProcessNotes.notes;
export const selectProcessNoteStatus = (state: RootState) => state.tailoredProcessNotes.status;

export default getTailoredProcessNotesSlice.reducer;
