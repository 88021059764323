import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { projectRoles } from "../../constants/ProjectRoles";
import { RootState } from "../store";

export interface ITailoringManagementState {
  status: "idle" | "success" | "error";
  projectRole: projectRoles;
  isDisabled: boolean;
}

const initialState: ITailoringManagementState = {
  status: "idle",
  projectRole: "Guest",
  isDisabled: true,
};

export const tailoringManagementSlice = createSlice({
  name: "tailoringManagement",
  initialState,
  reducers: {
    setProjectRole: (state, action: PayloadAction<projectRoles>) => {
      state.projectRole = action.payload;
      state.status = "success";
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload;
    },
    reset: (state) => {
      state.projectRole = "Guest";
      state.status = "idle";
      state.isDisabled = true;
    },
  },
  extraReducers: (builder) => {},
});

export const { setProjectRole, setDisabled, reset } =
  tailoringManagementSlice.actions;

export const selectCurrentProjectRole = (state: RootState) =>
  state.tailoringManagement.projectRole;

export default tailoringManagementSlice.reducer;
