import React from "react";
import { useAppSelector } from "../app/hooks";
import {
  selectUserFeatures,
  selectUserLoadingStatus,
} from "../app/slices/userSlice";
import Typography from "../components/common/Typography";
import { TypographyTypes } from "../utils/enum";
import { IFeatureAuthorizationProps } from "./types";

const UnAuthorized = () => {
  const authLoading = useAppSelector(selectUserLoadingStatus);

  return authLoading === "loading" ? (
    <Typography text="Loading..." type={TypographyTypes.default} />
  ) : (
    <Typography
      text="Sorry, you are not authorized to access this page"
      type={TypographyTypes.default}
      align="center"
    />
  );
};

export const WithFeatureAuthorization = (props: IFeatureAuthorizationProps) => {
  const { authType } = props;

  switch (authType) {
    case "page":
      return <WithPageAuthorization {...props} />;
    case "menu":
      return <WithMenuAuthorization {...props} />;
    case "action":
      return <WithActionAuthorization {...props} />;
    default:
      return <WithPageAuthorization {...props} />;
  }
};

export const WithPageAuthorization = ({
  children,
  featureId,
  authType,
}: IFeatureAuthorizationProps) => {
  const userFeatures = useAppSelector(selectUserFeatures);

  const authFeature = userFeatures.filter((feature) => feature === featureId);

  if (authFeature.length === 0) {
    return <UnAuthorized />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export const WithMenuAuthorization = ({
  children,
  featureId,
}: IFeatureAuthorizationProps) => {
  const userFeatures = useAppSelector(selectUserFeatures);

  const authFeature = userFeatures.filter((feature) => feature === featureId);

  if (authFeature.length === 0) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export const WithActionAuthorization = ({
  children,
  featureId,
  action = "hide",
}: IFeatureAuthorizationProps) => {
  const userFeatures = useAppSelector(selectUserFeatures);

  const authFeature = userFeatures.filter((feature) => feature === featureId);

  let disabled = false;

  if (authFeature.length === 0) {
    if (action === "disable") {
      disabled = true;
      return (
        <>{React.cloneElement(children as React.ReactElement, { disabled })}</>
      );
    } else {
      return null;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};
