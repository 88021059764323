import React from 'react'
import { Avatar as MAvatar, AvatarProps as MAvatarProps, Group, useMantineTheme } from '@mantine/core';
import { AvatarTypes } from '../../../utils/enum';
import { AvatarProps } from './types';

const Avatar = ({ type, label, radius, ...props }: MAvatarProps & AvatarProps) => {

         const theme = useMantineTheme();

      const getAvatar = () => {
            let avatar = <MAvatar data-testid="avatar-image-component" {...props} radius={radius || "xl"} color={props.color ? props.color : theme.colors.cyan[2]} />;
            switch (type){
                  case AvatarTypes.withText:
                        avatar = <Group>{avatar}{label}</Group>
                        return avatar;
                  default:
                        return avatar;
            }            
      }

      return getAvatar();
}

export default Avatar;