import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { ITailoredProcessNoteParams, addTailoredProcessNoteService } from "../../services/tailoringService";

export interface addTailoredProcessNoteState {
  status: "idle" | "loading" | "failed" | "success";
}

const initialState: addTailoredProcessNoteState = {
  status: "idle",
};

export const addTailoredProcessNote = createAsyncThunk(
  "tailoredProcessNote/addTailoredProcessNote",
  async (params: { instance: IPublicClientApplication; accounts: AccountInfo[]; data:ITailoredProcessNoteParams  }) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await addTailoredProcessNoteService({
      accessToken,
      ...params.data,
    });
    return response.data;
  }
);

export const addTailoredProcessNoteSlice = createSlice({
  name: "addTailoredProcessNote",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTailoredProcessNote.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addTailoredProcessNote.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(addTailoredProcessNote.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const selectAddTailoredProcessNote = (state: { addTailoredProcessNote: addTailoredProcessNoteState }) =>
  state.addTailoredProcessNote;

export default addTailoredProcessNoteSlice.reducer;
