import { IProjectAllocation } from "../@types/projectAllocation";
import { IProcess } from "../@types/processPanel";
import { ITailoredProcess } from "../@types/ProjectTailor";

interface ResultItem {
  label: string;
  value: string;
}

interface Item {
  id: number;
  name: string;
  colorCode?: string;
}

export const generateDropdown = (array: Item[] | undefined): ResultItem[] => {
  const res: ResultItem[] = [];
  if (array != undefined) {
    const memo = new Set();
    array.forEach((item) => {
      if (!memo.has(item.name)) {
        memo.add(item.name);
        res.push({ label: item.name, value: item.id.toString() });
      }
    });
  }
  return res;
};

export const handleUnsavedFormRouteChanges = (message:string) => {
    return window.confirm(message);
};

export const setUnsavedNavType = (status:string) => {
  localStorage.unsavedNavType = status;
};

export const getUnsavedNavPath = () => {
  return localStorage.unsavedNavType ;
};

export const setUnsavedChanges = (status:string) => {
  localStorage.unsavedChanges = status
};

export const unsavedChangesAvailable = () => {
  return localStorage.unsavedChanges === "true" ? true : false;
};

export interface userInitiator {
  id: number;
  name: string;
}

export const generateDropdownForInitiators = (
  array: IProjectAllocation[] | undefined
): ResultItem[] => {
  const res: ResultItem[] = [];
  if (array !== undefined) {
    const memo = new Set();
    array.forEach((item) => {
      if (!memo.has(item.user.name)) {
        memo.add(item.user.name);
        res.push({ label: item.user.name, value: item.user.id.toString() });
      }
    });
  }
  return res;
};

export const getAvatarText = (name: string) => {
  if (name) {
    const names = name.split(" ");
    if (names.length > 1) {
      return names[0][0] + names[1][0];
    } else {
      return names[0][0];
    }
  }
};

export const log = (value: any) => {
  console.log(value);
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getConditionLabelForNumber = (
  day: Number,
  trueValue: string,
  falseValue: string
) => (day === 1 ? trueValue : falseValue);

export interface IFormValuesToInput {
  [key: string]: IProcess[];
}

//initiatorId, reviewerId and methodology
const escapeType = ["initiatorId", "reviewerId", "methodologyId"]

// convert process panel form values to input for api
export const convertProcessPanelFormValuesToInput = (values: IFormValuesToInput) => {
  const result: ITailoredProcess[] = [];
  Object.keys(values).forEach((key) => {
    // if key is one of the escape type, skip it
    if (escapeType.includes(key)) return;
    
    values[key].forEach((item) => {
      result.push({
        id: item.tailoredProcessId,
        processMasterId: item.processMasterId,
        processAreaName: key,
        name: item.name,
        processToolTip: item.processTooltip,
        isApplicable: item.isApplicable,
        isApplicableMandatory: item.isApplicableMandatory,
        frequencyId: Number(item.frequencyId) || null,
        tools: item.tools?.map(x => Number(x)),
        roleId: item.roleId,
        ownerId: item.ownerId,
        initiatorNote: item.initiatorNote,
        approveNote: item.approveNote,
        processAreaOrderIndex: item.processAreaOrderIndex,
        processOrderIndex: item.processOrderIndex,
      });
    });
  });
  return result;
};

export const dateFormat = "DD MMM YYYY";
export const dateTimeFormat = "DD MMM YYYY HH:mm";