import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { successShowNotification } from "../../components/common/Toast";
import { getAccessToken } from "../../services/authService";
import {
  IReferenceHeaderParams,
  IReferenceCreateHeaderParams,
  IReferenceDeleteHeaderParams,
  deleteReference as deleteReferenceService,
  updateReference as updateReferenceService,
  getReferences as getReferencesService,
  createReference as createReferenceService,
  IReferenceUpdateHeaderParams,
} from "../../services/referenceService";
import { RootState } from "../store";

export interface IReference {
  id: number;
  url: string;
  comment: string;
  createdBy: string;
  updatedOn: string;
}

export interface referenceState {
  status: "idle" | "loading" | "failed";
  data: IReference[];
  isDeleting: Boolean;
}

const initialState: referenceState = {
  status: "idle",
  data: [],
  isDeleting: false,
};

interface IReferenceHeaderParameters {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  tailoringProcessId: string;
}

interface IReferenceUpdateHeaderParameters {
  message:string;
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  tailoredProcessReferenceId: string;
  tailoringProcessId: string;
  body: {
    url: string;
    comment: string;
  };
}

interface IReferenceCreateHeaderParameters {
  message:string;
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  tailoringProcessId: string;
  body: {
    url: string;
    comment: string;
  };
}

interface IReferenceDeleteHeaderParameters {
  message:string;
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  tailoredProcessReferenceId: string;
  tailoringProcessId: string;
}

export const getReferences = createAsyncThunk(
  `reference/getReferences`,
  async (params: IReferenceHeaderParameters) => {
    const accessToken = await getAccessToken(
      params.instance,
      params.accounts[0]
    );
    const response = await getReferencesService({
      accessToken,
      tailoringProcessId: params.tailoringProcessId,
    });
    return response.data;
  }
);

export const updateReferences = createAsyncThunk(
  `reference/updateReferences`,
  async (params: IReferenceUpdateHeaderParameters) => {
    const accessToken = await getAccessToken(
      params.instance,
      params.accounts[0]
    );
    await updateReferenceService({
      accessToken,
      tailoredProcessReferenceId: params.tailoredProcessReferenceId,
      tailoringProcessId: params.tailoringProcessId,
      body: params.body,
    });
    const response = await getReferencesService({
      accessToken,
      tailoringProcessId: params.tailoringProcessId,
    });
    successShowNotification("Successful", params.message);
    return response.data;
  }
);

export const createReferences = createAsyncThunk(
  `reference/createReferences`,
  async (params: IReferenceCreateHeaderParameters) => {
    const accessToken = await getAccessToken(
      params.instance,
      params.accounts[0]
    );
    await createReferenceService({
      accessToken,
      tailoringProcessId: params.tailoringProcessId,
      body: params.body,
    });
    const response = await getReferencesService({
      accessToken,
      tailoringProcessId: params.tailoringProcessId,
    });
    successShowNotification(
      "Successful",
      params.message
    );
    return response.data;
  }
);

export const deleteReferences = createAsyncThunk(
  `reference/deleteReferences`,
  async (params: IReferenceDeleteHeaderParameters) => {
    const accessToken = await getAccessToken(
      params.instance,
      params.accounts[0]
    );
    await deleteReferenceService({
      accessToken,
      tailoredProcessReferenceId: params.tailoredProcessReferenceId,
      tailoringProcessId: params.tailoringProcessId,
    });
    const response = await getReferencesService({
      accessToken,
      tailoringProcessId: params.tailoringProcessId,
    });
    successShowNotification("Successful", params.message);
    return response.data;
  }
);

export const referenceSlice = createSlice({
  name: "reference",
  initialState,
  reducers: {
    setReferenceData: (state, action: PayloadAction<IReference[]>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReferences.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload
          ? action.payload.length > 0
            ? action.payload
            : []
          : [];
      })
      .addCase(getReferences.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createReferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createReferences.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload
          ? action.payload.length > 0
            ? action.payload
            : []
          : [];
      })
      .addCase(createReferences.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteReferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteReferences.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload
          ? action.payload.length > 0
            ? action.payload
            : []
          : [];
      })
      .addCase(deleteReferences.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateReferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateReferences.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload
          ? action.payload.length > 0
            ? action.payload
            : []
          : [];
      })
      .addCase(updateReferences.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setReferenceData } = referenceSlice.actions;
export const selectReferences = (state: RootState) => state.reference.data;
export const selectReferencesStatus = (state: RootState) =>
  state.reference.status;

export default referenceSlice.reducer;
