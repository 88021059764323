export const ICON_SIZES = {
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 48,
  xxlarge: 64,
}

export const LOCAL_STORAGE_IDENTIFIER = {
  processPanel: "processPanel",
}
