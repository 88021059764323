import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getStatusesMasterData as getStatusesService } from "../../services/materDataService";
import { RootState } from "../store";

export interface State {
  status: "idle" | "loading" | "failed";
  statuses?: IStatusDetails[];
}

interface IStatusDetails {
  id: number;
  name: string;
  colorCode?: string;
}

const initialState: State = {
  status: "idle",
  statuses: [],
};

interface IMasterDataParameters {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
}

export const getStatusMasterData = createAsyncThunk(
  `masterData/getStatusMasterData`,
  async (params: IMasterDataParameters) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getStatusesService(accessToken);
    const data = response.data;
    return {
      data,
    };
  }
);

export const myProjectsSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatusMasterData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatusMasterData.fulfilled, (state, action) => {
        state.status = "idle";
        state.statuses = action.payload.data
          ? action.payload.data.length > 0
            ? action.payload.data
            : []
          : [];
      })
      .addCase(getStatusMasterData.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectStatuses = (state: RootState) => state.masterData.statuses;

export default myProjectsSlice.reducer;
