import {
	ALLOCATIONS,
	FREQUENCY,
	PROCESS_PANEL,
	PROCESS_SAMPLES,
	PROJECT_ROLES,
	TOOLS,
} from "../../constants/api-paths";
import axios from "../interceptors";
import envVariables from "../../utils/config.json";

const baseUrl = process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL;

export interface IProcessPanelService {
	projectId: string;
	methodologyId?: string;
	accessToken: string;
}

export const getProcessPanelService = async ({ projectId, methodologyId = "1", accessToken }: IProcessPanelService) => {
	return axios.get(baseUrl + PROCESS_PANEL(projectId, methodologyId), {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const getFrequencyService = async (accessToken: string) => {
	return axios.get(baseUrl + FREQUENCY, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const getToolsService = async (accessToken: string) => {
	return axios.get(baseUrl + TOOLS, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const getProjectRolesService = async (accessToken: string) => {
	return axios.get(baseUrl + PROJECT_ROLES, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const getProjectAllocationsService = async (projectId: string, accessToken: string) => {
	return axios.get(baseUrl + ALLOCATIONS(projectId), {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const getProcessSamplesService = async (processMasterId: string, accessToken: string) => {
	return axios.get(baseUrl + PROCESS_SAMPLES(processMasterId), {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};
