import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import AuthLayout from "./AuthLayout";
import UnauthLayout from "./UnauthLayout";

const Layout = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <AuthLayout />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthLayout />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Layout;
