import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getProcessSamplesService } from "../../services/processService";
import { IProcessSample } from "../../utils/@types/processPanel";

export interface processSampleState {
  data: IProcessSample | null;
  status: "idle" | "loading" | "failed" | "success";
}

const initialState: processSampleState = {
  data: null,
  status: "idle",
};

interface IGetProcessSamples {
  processMasterId: string;
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
}

export const getProcessSamples = createAsyncThunk(
  "processSample/getProcessSamples",
  async (params:IGetProcessSamples) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getProcessSamplesService(params.processMasterId, accessToken);
    return response.data;
  }
);

export const processSampleSlice = createSlice({
  name: "processSample",
  initialState,
  reducers: {
    // reset process sample
    resetProcessSample: (state) => {
      state.data = null;
      state.status = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessSamples.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProcessSamples.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getProcessSamples.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const selectProcessSample = (state: any) => state.processSample.data;
export const { resetProcessSample } = processSampleSlice.actions;

export default processSampleSlice.reducer;
