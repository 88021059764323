import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import {
  getTailoringHistory as getTailoringHistoryService,
  ITailoringHistoryHeaderParams,
} from "../../services/tailoringService";
import { RootState } from "../store";

export interface ITailoringHistory {
  isApplicable: boolean;
  frequency: string;
  role: string;
  owner: string;
  tools: string[];
  initiatorNote: string;
  approveNote: string;
  updatedBy: string;
  updatedOn: string;
}

export interface tailoringHistoryState {
  status: "idle" | "loading" | "failed";
  data: ITailoringHistory[];
}

interface ITailoringHistoryHeaderParameters {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  tailoringProcessId: string;
}

const initialState: tailoringHistoryState = {
  status: "idle",
  data: [],
};

export const getTailoringHistory = createAsyncThunk(
  `tailoringHistory/getTailoringHistory`,
  async (params: ITailoringHistoryHeaderParameters) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getTailoringHistoryService({accessToken, tailoringProcessId: params.tailoringProcessId});
    return response.data;
  }
);

export const tailoringHistorySlice = createSlice({
  name: "tailoringHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTailoringHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTailoringHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload
          ? action.payload.length > 0
            ? action.payload
            : []
          : [];
      })
      .addCase(getTailoringHistory.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectTailoringHistory = (state: RootState) =>
  state.tailoringHistory;

export default tailoringHistorySlice.reducer;
