import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { getProjectInitiator as getProjectInitiatorsService } from "../../services/materDataService";
import { userInitiator } from "../../utils/functions";
import { RootState } from "../store";

export interface projectInitiatorState {
  status: "idle" | "loading" | "failed" | "success";
  data: [{id:number;
  allocationStartDate:Date;
  allocationEndDate:Date;
  role:string;
  user: userInitiator;}] | [];
}

interface InitiatorParams {
  instance: IPublicClientApplication;
  accounts: AccountInfo[];
  query?: string;
}

const initialState: projectInitiatorState = {
  status: "idle",
  data: [],
};

export const getInitiators = createAsyncThunk(
  `initiator/getInitiators`,
  async (params: InitiatorParams) => {
    const accessToken = await getAccessToken(params.instance, params.accounts[0]);
    const response = await getProjectInitiatorsService({accessToken, query: params.query});
    const data = response.data;
    return {
      data,
    };
  }
);

export const initiatorSlice = createSlice({
  name: "initiator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInitiators.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInitiators.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.data ? action.payload.data : [];
      })
      .addCase(getInitiators.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectInitiator = (state: RootState) => state.initiator;

export default initiatorSlice.reducer;
