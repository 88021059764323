import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Icon SVGs
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setNavModalStatus as onSetUnsavedChanges } from "../../app/slices/navigationSlice";
import { ReactComponent as Apps } from "../../assets/images/icons/apps.svg";
import { ReactComponent as Assignment } from "../../assets/images/icons/assignment.svg";
import { ReactComponent as Devices } from "../../assets/images/icons/devices.svg";
import SideMenu from "../../components/SideNavigation";
import { MenuItemProps } from "../../components/SideNavigation/type";
import {
  setUnsavedNavType,
  unsavedChangesAvailable,
} from "../../utils/functions";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { infoShowNotification } from "../../components/common/Toast";
import { getAccessToken, getAuthAccessToken } from "../../services/authService";
import {
  getUserDetails as getUserDetailsSlice,
  getUserFeatures,
  getUserImage as getUserImageSlice,
  getUserRoles as getUserRolesSlice,
  selectUserDetails,
  selectUserImage,
} from "../../app/slices/userSlice";

const SideNavigationFeature = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation<"translation">();
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<number>();

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const userDetails = useAppSelector(selectUserDetails);
  const userImage = useAppSelector(selectUserImage);
  
  const menuItems: MenuItemProps[] = [
    {
      icon: <Apps />,
      label: translate("my_projects"),
      path: "/",
      feature: "menu-my-projects",
    },
    {
      icon: <Devices />,
      label: translate("all_projects"),
      path: "/all-projects",
      feature: "menu-all-projects",
    },
    {
      icon: <Assignment />,
      label: translate("pending_approval"),
      path: "/pending-approval",
      feature: "menu-pending-approvals",
    },
  ];

  const changePath = (path: string, index: number) => {
    navigate(path);
    setActive(index);
  };

  const handleNavigation = (path: string, index: number) => {
    if (unsavedChangesAvailable()) {
      dispatch(onSetUnsavedChanges(true));
      setUnsavedNavType(path);
    } else {
      changePath(path, index);
    }
  };

  useEffect(() => {
    const isRoleChanged = window.localStorage.getItem("isRoleChanged");
    if (Boolean(isRoleChanged)) {
       // show notification after 600ms
       setTimeout(() => {
        infoShowNotification(translate("role_modal_title"), translate("role_modal_message"))

        // clear the state 
        window.localStorage.removeItem("isRoleChanged");
      }
        , 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (isAuthenticated) {
      getuserDetails();
      getuserImage();
      getUserAuthDetails();
      getuserRoles();
    }
  }, [isAuthenticated]);

  const logout = () => {
    try {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getuserDetails = async () => {
    const accessToken = await getAuthAccessToken(instance, accounts[0]);
    dispatch(getUserDetailsSlice(accessToken));
  };

  const getuserImage = async () => {
    const accessToken = await getAuthAccessToken(instance, accounts[0]);
    dispatch(getUserImageSlice(accessToken));
  };

  const getUserAuthDetails = async () => {
    const accessToken = await getAccessToken(instance, accounts[0]);
    dispatch(getUserFeatures(accessToken));
  };

  const getuserRoles = async () => {
    const accessToken = await getAccessToken(instance, accounts[0]);
    dispatch(getUserRolesSlice(accessToken));
  };

  return (
    <SideMenu
      active={active}
      handleNavigation={handleNavigation}
      menuItems={menuItems}
      setActive={setActive}

      avatarUrl={userImage.imageURL}
      avatarAlt={userDetails.givenName[0] + userDetails.surname[0]}
      username={userDetails.displayName}
      logout={logout}
    />
  );
};

export default SideNavigationFeature;
