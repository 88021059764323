import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthRoles } from "../../constants/authFeature";
import {
  getUserDetails as getUserDetailsService,
  getUserFeatures as getUserFeaturesService,
  getUserImage as getUserImageService,
  getUserRoles as getUserRolesService,
  updateUserRole as updateUserRoleService,
} from "../../services/authService";
import { RootState } from "../store";

export interface UserState {
  details: Iuser;
  status: "idle" | "loading" | "failed" | "authLoading";
  authStatus: "idle" | "loading" | "failed";
  image: Iimage;
  features: string[];
  userRole: AuthRoles;
  availableRoles: IRoles[] | [];
  userId: number;
}

interface Iuser {
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
  id: string;
}

interface Iimage {
  imageURL: string;
}

interface IRoles {
  id: number;
  name: string;
}

interface IUpdateRoleData {
  accessToken: string;
  body: { UserId: number; RoleId: number };
  role: AuthRoles;
}

const initialState: UserState = {
  details: {
    displayName: "",
    givenName: "",
    surname: "",
    mail: "",
    id: "",
  },
  status: "idle",
  authStatus: "loading",
  image: {
    imageURL: "",
  },
  features: [],
  userRole: "User",
  availableRoles: [],
  userId: 0,
};

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (accessToken: string) => {
    const response = await getUserDetailsService(accessToken);

    const { displayName, mail, givenName, surname, id } = response.data;
    return {
      displayName,
      givenName,
      surname,
      mail,
      id,
    };
  }
);

export const getUserImage = createAsyncThunk(
  "user/getUserImage",
  async (accessToken: string) => {
    const response = await getUserImageService(accessToken);

    const imageURL = response?.data;

    return { imageURL };
  }
);

export const getUserFeatures = createAsyncThunk(
  "user/getUserFeatures",
  async (accessToken: string) => {
    const response = await getUserFeaturesService(accessToken);
    const data = response.data;
    return { data };
  }
);

export const getUserRoles = createAsyncThunk(
  "user/getUserRoles",
  async (accessToken: string) => {
    const response = await getUserRolesService(accessToken);
    const data = response.data;
    return { data };
  }
);

export const updateUserRole = createAsyncThunk(
  "user/updateUserRole",
  async ({ accessToken, body, role }: IUpdateRoleData) => {
    await updateUserRoleService(accessToken, body);
    return { data: role };
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.details.displayName = action.payload?.displayName;
        state.details.givenName = action.payload?.givenName;
        state.details.surname = action.payload?.surname;
        state.details.mail = action.payload?.mail;
        state.details.id = action.payload?.id;
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUserImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserImage.fulfilled, (state, action) => {
        state.status = "idle";
        state.image.imageURL = action.payload?.imageURL || "";
      })
      .addCase(getUserImage.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUserFeatures.pending, (state) => {
        state.authStatus = "loading";
      })
      .addCase(getUserFeatures.fulfilled, (state, action) => {
        state.authStatus = "idle";
        state.userRole = action.payload?.data?.role;
        state.features = action.payload?.data?.features;
        state.userId = action.payload?.data?.userId;
      })
      .addCase(getUserFeatures.rejected, (state) => {
        state.authStatus = "failed";
      })
      .addCase(getUserRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserRoles.fulfilled, (state, action) => {
        state.status = "idle";
        state.availableRoles = action.payload?.data;
      })
      .addCase(getUserRoles.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateUserRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        state.status = "idle";
        state.userRole = action.payload?.data;
      })
      .addCase(updateUserRole.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectUserDetails = (state: RootState) => state.user.details;
export const selectUserImage = (state: RootState) => state.user.image;
export const selectUserFeatures = (state: RootState) => state.user.features;
export const selectUserRole = (state: RootState) => state.user.userRole;
export const selectUserId = (state: RootState) => state.user.userId;
export const selectAvailableUserRole = (state: RootState) =>
  state.user.availableRoles;
export const selectUserLoadingStatus = (state: RootState) =>
  state.user.authStatus;

export default userSlice.reducer;
