import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/authService";
import { ISaveSubmitProjectTailorService, saveSubmitProjectTailorService } from "../../services/tailoringService";
import { ISaveSubmitProjectTailorParams, TailoringSaveType } from "../../utils/@types/ProjectTailor";

export interface IProjectRoleState {
	data: any;
	status: "idle" | "loading" | "failed" | "success";
}

const initialState: IProjectRoleState = {
	data: "",
	status: "idle",
};

export interface ISaveSubmitProjectTailorParameters {
	instance: IPublicClientApplication;
	accounts: AccountInfo[];
	params: ISaveSubmitProjectTailorParams;
	tailoringSaveType: TailoringSaveType;
	tailoringRecordId: number;
}

export const saveSubmitProjectTailor = createAsyncThunk(
	"projectTailorSaveSubmit/saveProjectTailor",
	async (params: ISaveSubmitProjectTailorParameters) => {
		try {
			const accessToken = await getAccessToken(params.instance, params.accounts[0]);

			const response = await saveSubmitProjectTailorService({accessToken, 
				tailoringRecordId: params.tailoringRecordId, 
				params: params.params, 
				tailoringSaveType: params.tailoringSaveType});
			return response.data;
		} catch (error) {
		}
		
	}
);

export const projectTailorSaveSubmitSlice = createSlice({
	name: "projectTailorSaveSubmit",
	initialState,
	reducers: {
		resetData: (state) => {
			state.data = "";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(saveSubmitProjectTailor.pending, (state) => {
				state.status = "loading";
			})
			.addCase(saveSubmitProjectTailor.fulfilled, (state, action) => {
				state.status = "success";
				state.data = action.payload;
			})
			.addCase(saveSubmitProjectTailor.rejected, (state) => {
				state.status = "failed";
			});
	},
});

export const selectProjectTailorSaveSubmit = (state: {
  projectTailorSaveSubmit: IProjectRoleState;
}) => state.projectTailorSaveSubmit;

export const { resetData } = projectTailorSaveSubmitSlice.actions;

export default projectTailorSaveSubmitSlice.reducer;
