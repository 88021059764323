import { useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import { authRequest } from "./authConfig";

const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    try {
      if (!isAuthenticated && inProgress !== "login") login();
    } catch (error) {
      console.log(error);
    }
  }, [isAuthenticated]);

  const login = () => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }

    instance.addEventCallback(
      (event: { eventType: EventType; payload: { account: any } }) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          const account = event.payload.account;
          instance.setActiveAccount(account);
        }
      }
    );
    instance
      .handleRedirectPromise()
      .then((authResult) => {
        const account = instance.getActiveAccount();
        if (!account) {
          instance.loginRedirect(authRequest);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return null;
};

export default Login;
