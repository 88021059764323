import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  link: {
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fill: "#969696",
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },

    button: {
      backgroundColor: "red !important",
    },
  },

  nav: {
    width: 80,
  },

  collapseNav: {
    width: 220,
  },

  collapseLink: {
    width: 150,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fill: "#969696",
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      fontWeight: 500,
      fill: "#00D435",
    },
  },

  collapsedActive: {
    "&, &:hover": {
      fill: "#00D435",
    },
  },

  logo: {
    img: {
      borderRadius: "10px",
    },
    padding: "2px",
    margin: "0 0 0 auto",
  },

  logoSectionTable: {
    "tbody tr td": {
      padding: "3px",
      border: "none",
    },

    "tbody tr td div": {
      margin: "0 auto 0 0",
    },
  },

  companyName: {
    fontSize: "1.2rem",
    fontWeight: 500,
    lineHeight: 0,
  },

  tagline: {
    color: "#969696",
    fontSize: "0.9rem",
    marginTop: "-21px",
  },

  collapseIcon: {
    button: {
      backgroundColor: "#F4F4F4",
      color: "#969696",
      borderRadius: "50%",
      width: "1.5rem",
      height: "1.5rem",
      margin: "0 0 0 auto",
      marginTop: "-10px",
    },
  },

  collapseIconCollapsed: {
    button: {
      backgroundColor: "#F4F4F4",
      color: "#969696",
      borderRadius: "50%",
      width: "1.5rem",
      height: "1.5rem",
      margin: "0 auto",
      marginTop: "-10px",
    },
  },

  menuStack: {
    button: {
      width: "100%",
      justifyContent: "left",
      padding: "0rem 2px",
    },
  },
  countIndicator: {
    float: "right",
    textAlign: "right",
    marginLeft: "1rem",
    div: {
      backgroundColor: "#1EBC37",
      color: "white",
    },
  },

  divider: {
    marginTop: "10px",
  },

  expandedMenuIcons: {
    marginLeft: "11px",
  },

  userName: {
    fontSize: "0.9rem",
  },

  logoutIcon: {
    "button": {
      width: "50px",
      height: "45px",
      margin: "0 auto",
      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[5]
            : theme.colors.gray[1],
      },
    },
  },

  logoutSection: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    },
  },

  userAvatarCollapsed: {
    width: "50px",
    height: "45px",
    "div.mantine-1h8xsbo": {
      margin: "0 auto",
      marginTop: 6,
    },
  },

  userAvatar: {
    width: "46px",
    height: "45px",
    "div.mantine-1h8xsbo": {
      margin: "0 auto",
      marginTop: 6,
    },
  },
}));

export default useStyles;
